import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MyLocation } from '@material-ui/icons';

const styles = (theme) => ({
  btn: {
    width: 40,
    height: 40,
    padding: '0.5px',
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    fontFamily: 'Roboto, Arial, sans-serif',
    border: 'unset',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#404040',
    marginRight: 10,
    marginBottom: 10,

    '&:hover': {
      color: '#272727',
    },

    '&:focus': {
      outline: 'unset',
    },
  },
});

class LocationButton extends Component {
  handleRelocate = () => {
    const { navigator } = window;
    const { recenter } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        recenter(pos);
      });
    }
  };

  componentDidMount() {
    this.locationBtn = document.getElementById('location-btn');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map !== this.props.map) {
      this.props.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(this.locationBtn);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <button id="location-btn" className={classes.btn} onClick={this.handleRelocate}>
        <MyLocation/>
      </button>
    );
  }
}

export default withStyles(styles)(LocationButton);
