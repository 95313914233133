import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import PrivateRouter from '../PrivateRouter';
import Login from '../Login';
import StayTracker from '../StayTracker';

export class App extends Component {
  componentDidMount() {
    const { userStore } = this.props.rootStore;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        userStore.login(user);
      } else {
        this.props.history.push('/login');
      }
    });
  }

  render() {
    const { userStore } = this.props.rootStore;
    const isAuthenticated = (userStore && userStore.isAuthenticated) || false;
    const isAuthorized = (userStore && userStore.isAuthorized) || false;

    return (
      <React.Fragment>
        <Switch>
          <Route path="/login" exact component={Login} />
          <PrivateRouter path="/stay-tracker" exact isAuthenticated={isAuthenticated} isAuthorized={isAuthorized} component={StayTracker} />
          <Redirect from="/" to="/stay-tracker" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(inject('rootStore')(observer(App)));
