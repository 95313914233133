import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../../components/Loading';
import LogoutDialog from '../../components/LogoutDialog';
import OccupancyMap from './containers/OccupancyMap';
import SiteSelector from './containers/SiteSelector';

const styles = () => ({
  root: {
    height: '100vh',
  },
});

class StayTracker extends Component {
  componentDidMount() {
    const { orgStore } = this.props.rootStore;
    orgStore.initData();
  }

  isLoading = () => {
    const { groupStore, orgStore, deviceStore } = this.props.rootStore;
    return groupStore.loading || orgStore.loading || deviceStore.loading;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Loading render={() => <OccupancyMap/>} isLoading={this.isLoading()}/>
        <SiteSelector/>
        <LogoutDialog/>
      </div>
    );
  }
}

export default withStyles(styles)(inject('rootStore')(observer(StayTracker)));
