import React from 'react';

interface RowProps {
  label?: any,
  value: JSX.Element | string,
}

export default function Row({ label, value }: RowProps): JSX.Element {
  return (
    <tr>
      <td className='occupancy-map-key'>
        {label ? `${label}: ` : ''}
      </td>
      <td className='occupancy-map-value'>
        {value}
      </td>
    </tr>
  );
}