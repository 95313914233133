import React from 'react';
import Row from './Row';
import Counter from './Counter';
import LastState from './LastState';
import PaymentInfo from './PaymentInfo';
import { getStateMinutes, readableTime } from './utils';
import { LinearProgress } from '@material-ui/core';
import { StayTrackerDevice, GroupMap } from '../../stores/DeviceStore';

interface ContainerProps {
  device: StayTrackerDevice,
  groups: GroupMap[],
  timezone: string,
  isLoading: boolean,
  paymentError: boolean,
}

export default function Container({ device, groups, timezone, isLoading, paymentError }: ContainerProps): JSX.Element {
  const { viewStates, states = {}, groupStates = [] } = device;
  const stateArray: string[] = (viewStates) ? Object.keys(viewStates) : [];

  const getGroupState = (key: string) => {
    const relevantStates = groupStates.filter((it) => Object.keys(it.states).includes(key));
    if (relevantStates.length > 0) {
        const highestGroupType = relevantStates[0].type;
        const tsSorted = relevantStates.filter((it) => it.type === highestGroupType)
            .sort((a, b) => (b.states[key].currentState.timestamp as number) - (a.states[key].currentState.timestamp as number));
        return tsSorted[0].states[key] && tsSorted[0].states[key].currentState;
    }
  }

  const maxStay = getGroupState('sp_max_staytime');
  const gracePeriod = getGroupState('sp_enforcement_grace_period');

  const drawStatus = (statusClass: string, state: string): JSX.Element => {
    return (
      <>
        <span className={statusClass}>
          {`${state} for ${readableTime(viewStates[state].start)}`}
        </span>
        <br/>
      </>
    );
  }

  const drawConfidence = (occState: any): JSX.Element => {
    if (occState.skipRulesCheck === 1) {
      return (<Row label='Confidence' value={
        <>
          <span className={'occupied'}>
            {`Low`}
          </span>
          <br/>
        </>
      }/>
      );
    }
    else {
      return (<></>);
    }
  }

  return (
    <div>
      <div className='occupancy-map-header'>
        <h5>
          {device.device.name} {groups.filter((group: GroupMap) => device.groups[`${group.parent}#${group.id}`]).map((g, index) => (
            <label key={index} className='occupancy-map-group-label'>
              {g.name}
            </label>
          ))}
        </h5>
      </div>
      <div className='occupancy-map-content'>
        <table className="occupancy-map-general-info">
          <tbody>
            {stateArray.includes('Maintenance') && <Row label='Under Maintenance' value={viewStates['Maintenance'].label}/>}
            {stateArray.includes('Question') && <Row value={viewStates['Question'].label}/>}
            {stateArray.includes('Reserved') && <Row label='Reserved' value={viewStates['Reserved'].label}/>}
            {(stateArray.includes('Unassigned') && !stateArray.includes('Counter') && <Row label='Status' value='Unassigned'/>) ||
              (stateArray.includes('Offline') && <Row label='Status' value='Offline'/>) ||
              (stateArray.includes('Counter') && <Counter state={viewStates['Counter']} metadata={device.metadata}/>) ||
              <>
                {maxStay
                  && <Row label='Max stay time' value={getStateMinutes(maxStay.value)}/>}
                {gracePeriod
                  && <Row label='Grace period' value={getStateMinutes(gracePeriod.value)}/>}
                {stateArray.includes('Overstay') && <Row label='Status' value={drawStatus('overstay', 'Overstay')}/>}
                {stateArray.includes('Occupied') && <Row label='Status' value={drawStatus('occupied', 'Occupied')}/>}
                {stateArray.includes('Occupied') && drawConfidence(viewStates['Occupied'])}
                {stateArray.includes('Vacant') && <Row label='Status' value={drawStatus('vacant', 'Vacant')}/>}
                {stateArray.includes('Vacant') && drawConfidence(viewStates['Vacant'])}
              </>
            }
            <>
              {states && <LastState states={states} timezone={timezone}/>}
            </>
          </tbody>
        </table>
        {isLoading ?
          <LinearProgress id='payment-linear-loading-bar'/>
        : <PaymentInfo state={states.sp_payment_state} timezone={timezone} paymentError={paymentError}/>}
      </div>
    </div>
  )
}