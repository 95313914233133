import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Selector from '../../../components/Selector';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class SiteSelector extends Component {
  handleClose = () => {
    const { appStore } = this.props.rootStore;
    appStore.toggleSelector(false);
  };

  handleOrgChange = (e) => {
    const { orgStore } = this.props.rootStore;
    orgStore.switchOrg(e.target.value);
  };

  handleRegionChange = (e) => {
    const { appStore } = this.props.rootStore;
    appStore.changeRegion(e.target.value);
  };

  handleSiteChange = (e) => {
    const { appStore } = this.props.rootStore;
    appStore.changeSite(e.target.value);
  };

  handleSectorChange = (e) => {
    const { appStore } = this.props.rootStore;
    const updateSector = true;
    appStore.changeSector(e.target.value, updateSector);
  };

  render() {
    const { classes } = this.props;
    const { appStore, orgStore, groupStore } = this.props.rootStore;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={appStore.showSelector}
        onClose={this.handleClose}
      >
        <DialogTitle>Choose Site</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <Selector
              value={appStore.organization}
              inputId='organization'
              label='Organization'
              options={orgStore.organizationSelectOptions}
              disabled={orgStore.organizationSelectOptions.length <= 1}
              onChange={this.handleOrgChange}
            />
            <Selector
              value={appStore.region}
              inputId='region'
              label='region'
              options={groupStore.regionOptions}
              disabled={groupStore.regionOptions.length <= 1}
              onChange={this.handleRegionChange}
            />
            <Selector
              value={appStore.site}
              inputId='site'
              label='site'
              options={groupStore.siteOptions}
              disabled={groupStore.siteOptions.length <= 1}
              onChange={this.handleSiteChange}
            />
{/*             {appStore.organization === 'cityOfAdelaide' && groupStore.sectorOptions && groupStore.sectorOptions[0] !== undefined ?
              <Selector
                value={appStore.sector}
                inputId='sector'
                label='sector'
                options={groupStore.sectorOptions}
                disabled={groupStore.sectorOptions.length <= 1}
                onChange={this.handleSectorChange}
              /> : null } */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(inject('rootStore')(observer(SiteSelector)));
