import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { EditLocation } from '@material-ui/icons';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    padding: '3px 10px',
    borderRadius: 3,
    marginBottom: 10,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px -1px 4px -1px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  head: {
    margin: 0,
    fontSize: 14,
  },
  icon: {
    fontSize: 14,
  },
  siteInfo: {
    fontSize: 14,
    fontWeight: 900,
    margin: 0,
  },
  button: {
    width: 28,
    height: 28,
    padding: 0,
  },
  vacantnumbers: {
    color: '#00880c',
    fontWeight: 800,
  },
  occupiednumbers: {
    color: '#F44336',
    fontWeight: 800,
  },
});

class SiteName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      states: { vacancies: 0, occupied: 0, unknown: 0, total: 0 },
    }
  }
  componentDidMount() {
    this.siteName = document.getElementById('site-name-panel');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map !== this.props.map) {
      this.props.map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(this.siteName);
    }
  }

  handleBtnClick = () => {
    const { appStore } = this.props.rootStore;
    appStore.toggleSelector(true);
  };

  _showShow() {
    const { orgStore, groupStore } = this.props.rootStore;
    return !(orgStore.organizations.length <= 1 && groupStore.regions.length <= 1 && groupStore.sites.length <= 1 && groupStore.sectors.length <= 1);
  }

  render() {
    const { classes, rootStore: { groupStore, orgStore } } = this.props;
    return (
      <div id="site-name-panel" className={classes.root}>
        <p className={classes.siteInfo}>
          Total {this.props.total}
        </p>
        <p className={classes.head}>
          {(groupStore.sector && groupStore.sector.group.name) 
            || (groupStore.site && groupStore.site.group.name)
            || (groupStore.region && groupStore.region.group.name)
            || (orgStore.organization && orgStore.organization.organization.name)}
          {this._showShow() && (
            <IconButton color="primary" className={classes.button} component="span" onClick={this.handleBtnClick}>
              <EditLocation />
            </IconButton>
          )}
        </p>
      </div>
    );
  }
}

export default withStyles(styles)(inject('rootStore')(observer(SiteName)));
