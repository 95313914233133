import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRouter = ({ component: Component, ...props }) => <Route {...props} render={() => props.isAuthenticated && props.isAuthorized ?
  <Component {...props}/> :
  <Redirect to={{
    pathname: '/login',
    state: { from: props.location },
  }}/>}/>;

export default PrivateRouter;
