import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseui from 'firebaseui';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import ErrorToast from '../../components/ErrorToast';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  root: {
    background: 'url(/loginBG.jpg) no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    position: 'relative',
  },
  content: {
    backgroundColor: 'rgba(0, 0, 0, 0.62)',
    position: 'absolute',
    height: '100%',
    width: 400,
    left: '10%',
    top: 0,
  },
  header: {
    marginTop: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  brand: {
    fontFamily: 'Roboto',
    fontSize: '1.5em',
    color: '#f94f33',
    marginBottom: 0,
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: '3em',
    color: '#f94f33',
    marginTop: 0,
  },
  '@media (max-width: 420px)': {
    content: {
      width: '100vw',
      left: 0,
    },
  },
  '@media (max-width: 320px)': {
    logo: {
      marginTop: -60,
      width: 120,
    },
  },
  '@media (max-width: 360px)': {
    logo: {
      marginTop: -40,
      width: 140,
    },
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
  }

  componentDidMount() {
    const that = this;
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult(authResult, redirectUrl) {
          that.props.history.push('/stay-tracker');
          return false;
        },
      },
      signInFlow: 'popup',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          scopes: ['https://www.googleapis.com/auth/plus.login'],
          customParameters: {
            prompt: 'select_account',
          },
        },
      ],
      tosUrl: 'http://www.smartparking.com',
      privacyPolicyUrl: 'http://www.smartparking.com',
    };

    this.ui.start('#loginPane', uiConfig);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.rootStore.userStore.isAuthenticated) {
      const { from } = this.props.location.state || { from: { pathname: '/smart-park' } };
      this.props.history.push(from);
    }
  }

  componentWillUnmount() {
    if(this.props.rootStore.userStore.isAuthorized) {
      this.ui.delete();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.header}>
            <img src="/favicon-192x192.png" alt="logo" className={classes.logo} />
            <h3 className={classes.brand}>SmartCloud</h3>
            <h1 className={classes.name}>StayTracker</h1>
          </div>
          <div id="loginPane" />
        </div>
        <ErrorToast />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(inject('rootStore')(observer(Login))));
