import { observable, computed, action, decorate } from 'mobx';
import firebase from '../utils/firebase';
import { me } from '../services';

class UserStore {
  user = {};
  willLogout = false;
  error = {};
  isAuthorized = false;

  constructor(root) {
    this.root = root;
  }

  get isAuthenticated() {
    return this.shouldAuthorize();
  }

  shouldAuthorize() {
    if (!this.user || Object.keys(this.user).length === 0) {
      return false;
    }
    const scPermission = this.user.permissions.find((it) => it.groupRef === 'smartparking#smartcloud');
    let isGlobal = scPermission && scPermission.roles.some((it) => ['Super Global', 'Global'].includes(it));

    if (!isGlobal && scPermission && scPermission.roles.includes('Regional Global')) {
      // check if user has global access to an organization
      isGlobal = Boolean(this.user.permissions.find((it) => it.organization === 'smartparking' && it.type === 'organization' && it.roles.includes('Global')));
    }

    const appPermission = this.user.permissions.find((it) => it.groupRef === 'app#staytracker');
    const hasAppRoles = appPermission && (appPermission.roles.includes('User') || appPermission.roles.includes('Global'));

    return isGlobal || hasAppRoles;
  }

  async login(user) {
    this.user = await me();
    this.isAuthorized = this.shouldAuthorize();

    if (!this.isAuthorized) {
      this.error = { message: 'Sorry, you don\'t have the permission. Any questions, feel free to contact us!' };
      await this.doLogout();
    }
  }

  logout() {
    this.willLogout = true;
  }

  cancelLogout() {
    this.willLogout = false;
  }

  async doLogout() {
    this.willLogout = false;
    this.root.appStore.clearLocalStorage();
    this.root.orgStore.loading = true;
    this.root.appStore.organization = undefined;
    await firebase.auth().signOut();
  }

  clearErrorState() {
    this.error = {};
  }
}

export default decorate(UserStore, {
  user: observable,
  willLogout: observable,
  error: observable,

  isAuthenticated: computed,
  isAuthorized: observable,

  login: action,
  logout: action,
  cancelLogout: action,
  doLogout: action,

  clearErrorState: action,
});
