import { fetchGroups } from '../services';
import { action, computed, decorate, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import isEmpty from 'lodash/isEmpty';

class GroupStore {
  groups = observable.array([]);
  page = 0;
  pageSize = 5;
  loading = true;
  error = {};

  constructor(root) {
    this.root = root;
  }

  get sites() {
    return this.groups
      .filter((group) => group.group.type === 'site')
      .filter((group) => group.group.parent === this.root.appStore.region);
  }

  get siteOptions() {
    return this.sites.map((site) => ({
      key: site.group.id,
      value: site.group.id,
      text: site.group.name,
    }));
  }

  get site() {
    if (this.root.appStore.site && !isEmpty(this.groups)) {
      return this.sites.find((site) => site.group.id === this.root.appStore.site);
    }

    return null;
  }

  get sectors() {
    return this.groups
      .filter((group) => group.group.type === 'sector')
      .filter((group) => group.group.parent === this.root.appStore.site);
  }

  get sector() {
    return this.sectors 
      .find((group) => group.group.id === this.root.appStore.sector);
  }

  get sectorOptions() {
    const sectorOptions = this.sectors 
      .filter((group) => group.group.type === 'sector')
      .filter((group) => group.group.parent === this.root.appStore.site)
      .map((sector) => ({
        key: sector.group.id,
        value: sector.group.id,
        text: sector.group.name,
      }));
    //alphabetically sort sector names
    sectorOptions.sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));
    return sectorOptions;
  }

  get location() {
    const center = { lat: 0, lng: 0 };
    if (this.site && this.site.metadata && this.site.metadata.location) {
      const location = JSON.parse(this.site.metadata.location);
      center.lat = parseFloat(location.latitude);
      center.lng = parseFloat(location.longitude);
    }
    return center;
  }

  get regions() {
    return this.groups.filter((group) => group.group.type === 'region');
  }

  get regionOptions() {
    return this.regions.map((site) => ({
      key: site.group.id,
      value: site.group.id,
      text: site.group.name,
    }));
  }

  get region() {
    if (this.root.appStore.region && !isEmpty(this.groups)) {
      return this.regions.find((region) => region.group.id === this.root.appStore.region);
    }

    return null;
  }

  get groupTypes() {
    const types = [];
    this.groups
      .filter((group) =>
        group.group.type.toLowerCase() !== 'organization' &&
          group.group.type.toLowerCase() !== 'site' &&
          group.group.type.toLowerCase() !== 'region' &&
          group.group.type.toLowerCase() !== 'group' && 
          group.group.type.toLowerCase() !== 'sector')
      .forEach((group) => {
        if (!types.includes(group.group.type)) {
          types.push(group.group.type);
        }
      });
    return types;
  }

  get deviceGroups() {
    return createTransformer((device) => this.groups
      .filter((group) =>
        group.group.type.toLowerCase() !== 'organization' &&
            group.group.type.toLowerCase() !== 'site' &&
            group.group.type.toLowerCase() !== 'region' &&
            group.group.type.toLowerCase() !== 'group')
      .filter((group) => {
        const groupRef = `${group.group.parent}#${group.group.id}`;
        return Object.keys(device.groups).includes(groupRef);
      }));
  }

  get siteGroups() {
    return this.groups
      .filter((group) =>
        group.group.type.toLowerCase() !== 'organization' &&
          group.group.type.toLowerCase() !== 'site' &&
          group.group.type.toLowerCase() !== 'region' &&
          group.group.type.toLowerCase() !== 'group')
      .filter((group) => this.site.group.id === group.group.parent);
  }

  get timezone() {
    return this.site && this.site.metadata && this.site.metadata.timeZone;
  }

  clearData() {
    this.groups.clear();
  }

  async getGroups(orgId, switchingOrg) {
    this.loading = true;
    this.clearData();
    try {
      const responseData = await fetchGroups(orgId);
      this.groups = observable.array(responseData.groups);

      this.root.appStore.changeRegion(this.regions[0].group.id);
      this.root.appStore.changeSite(this.sites[0].group.id, switchingOrg);
      if (switchingOrg) this.root.appStore.changeOrganization(orgId);
      this.loading = false;
      this.error = {};
    } catch (error) {
      console.error('Unable to fetch groups', error);
      this.error = error;
    }
  }

  clearErrorState() {
    this.error = {};
  }

  selectPage(page) {
    this.page = page;
  }

  changePageSize(pageSize) {
    this.pageSize = pageSize;
  }
}

export default decorate(GroupStore, {
  groups: observable,
  page: observable,
  pageSize: observable,
  loading: observable,
  error: observable,

  sites: computed,
  siteOptions: computed,
  location: computed,
  site: computed,
  regions: computed,
  regionOptions: computed,
  region: computed,
  groupTypes: computed,
  deviceGroups: computed,
  siteGroups: computed,
  timezone: computed,

  clearData: action,
  getGroups: action,
  clearErrorState: action,
  selectPage: action,
  changePageSize: action,
});
