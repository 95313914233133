import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

class LogoutDialog extends React.Component {
  handleClose = () => {
    const { userStore } = this.props.rootStore;
    userStore.cancelLogout();
  };

  handleLogout = async () => {
    const { userStore } = this.props.rootStore;
    await userStore.doLogout();
  };

  render() {
    const { userStore } = this.props.rootStore;
    return (
      <Dialog
        open={userStore.willLogout}
        onClose={this.handleClose}
      >
        <DialogTitle><Warning color='error'/>Logout?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">No</Button>
          <Button onClick={this.handleLogout} color="primary" autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default inject('rootStore')(observer(LogoutDialog));
