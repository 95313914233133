const red = '#fb0000';
const white = '#ffffff';
const green = '#43d416';
const purple = '#a020f0';
const grey = '#d3d3d3';
const blueText = '#0000fb';

//list of default icons
module.exports = {
  icons: {
    deactivated: svgTemplate(grey, blueText, 'D'),
    initialise: svgTemplate(grey, blueText, 'P'),
    maintenance: svgTemplate(grey, blueText, 'M'),
    offline: svgTemplate(grey, red, 'P'),
    offline_paid: svgTemplate(grey, red, '$'),
    offline_reserved: svgTemplate(grey, red, 'R'),
    occupied: svgTemplate(red, white, 'P'),
    occupied_paid: svgTemplate(red, white, '$'),
    occupied_question: svgTemplate(red, white, '?'),
    occupied_reserved: svgTemplate(red, white, 'R'),
    overstay: svgTemplate(purple, white, 'P'),
    overstay_paid: svgTemplate(purple, white, '$'),
    overstay_question: svgTemplate(purple, white, '?'),
    overstay_reserved: svgTemplate(purple, white, 'R'),
    unassigned: svgTemplate(grey, blueText, 'U'),
    unassigned_paid: svgTemplate(grey, blueText, '$'),
    unassigned_reserved: svgTemplate(grey, blueText, 'R'),
    vacant: svgTemplate(green, white, 'P'),
    vacant_paid: svgTemplate(green, white, '$'),
    vacant_question: svgTemplate(green, white, '?'),
    vacant_reserved: svgTemplate(green, white, 'R'),
    uParkCounter: '/imgs/UParkCounter.png',
  },
};

function svgTemplate(circleFill, textFill, symbol, shadow) {
  let svgBuilder = `<svg  xmlns="http://www.w3.org/2000/svg" 
  xml:space="preserve" 
  style="fill-rule:evenodd"
  width="50" 
  height="50" 
  viewBox="-1 -1 52 52"
  id="deviceMarker">\n`;

  if (shadow) {
    svgBuilder += `<defs>
    <filter id="innershadow" x="-50%" y="-50%" width="200%" height="200%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="5" result="blur"></feGaussianBlur>
      <feOffset dy="2" dx="3"></feOffset>
      <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>
      
      <feFlood flood-color="${shadow}" flood-opacity="1"></feFlood>
      <feComposite in2="shadowDiff" operator="in"></feComposite>
      <feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite>
                
      <feGaussianBlur in="firstfilter" stdDeviation="3" result="blur2"></feGaussianBlur>
      <feOffset dy="-2" dx="-3"></feOffset>
      <feComposite in2="firstfilter" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>
      
      <feFlood flood-color="${shadow}" flood-opacity="1"></feFlood>
      <feComposite in2="shadowDiff" operator="in"></feComposite>
      <feComposite in2="firstfilter" operator="over"></feComposite>
    </filter>    
  </defs>\n`;
  }

  svgBuilder += `<circle
  cx="25"
  cy="25"
  r="25"
  fill="${circleFill}"`;
  
  if (shadow) { 
    svgBuilder += `\nfilter="url(#innershadow)"
    stroke="${shadow}"
    stroke-width="1"
    stroke-alignment="inner"`;
  }

  svgBuilder += `/>\n<text
    font-size="29"
    fill="${textFill}"
    font-weight="550"
    text-anchor="middle"
    font-family="Roboto, Arial, sans-serif"
    alignment-baseline="baseline"
    x="25"
    y="35">${symbol}</text>
  </svg>`;
  
  return svgBuilder;
}
