import moment from 'moment-timezone';

export function readableTimeWithDays(timestamp: number): string {
  let desc = '';
  let timeDiff: number = (new Date().getTime() - new Date(timestamp).getTime()) / 1000 / 60;  // convert to minutes

  let day: number = Math.floor(timeDiff / 1440); // 60 * 24
  let hour: number = Math.floor(timeDiff / 60 % 24);
  let minute: number = Math.floor(timeDiff % 60);

  if (day > 0) desc += `${day} ${day > 1 ? 'days' : 'day'}`
  if (hour > 0) desc += ` ${hour} ${hour > 1 ? 'hours' : 'hour'}`;
  if (minute > 0) desc += ` ${minute} ${minute > 1 ? 'minutes' : 'minute'}`;
  if (desc === '') desc = 'less than a minute';

  return desc;
}

export function readableTime(timestamp: number, isUTC?: boolean): string {
  let desc: string = '';
  let timeDiff: number = 0;

  if (isUTC) {
      let timenow: moment.Moment = moment.utc(new Date());
      let ts: moment.Moment = moment.utc(timestamp);
      timeDiff = (timenow.valueOf() - ts.valueOf()) / 1000 / 60;
  }
  else {
      timeDiff = (new Date().getTime() - new Date(timestamp).getTime()) / 1000 / 60; // convert to minutes
  }

  let hour: number = Math.floor(timeDiff / 60);
  let minute: number = Math.floor(timeDiff % 60);

  if (hour > 0) desc += `${hour} ${hour > 1 ? 'hours' : 'hour'}`;
  if (minute > 0) desc += ` ${minute} ${minute > 1 ? 'minutes' : 'minute'}`;
  if (desc === '') desc = 'less than a minute';

  return desc;
}

export function getStateMinutes(value: number): string {
  const minutes: number = Math.floor(value) / 60;
  return `${minutes} ${minutes > 1 || minutes === 0 ? 'minutes' : 'minute'}`;
}

export function formatDate(timestamp: number, timezone: string): string {
  return moment(timestamp).tz(timezone).format('DD/MM/YYYY, HH:mm');
}

export function shouldDisplayPayment (payment: any, timezone: string): boolean {
  if (payment) {
    const today: moment.Moment = moment.tz(timezone).startOf('day');
    const compareDay: moment.Moment = moment.tz(payment.expiryTimestamp, timezone).startOf('day');
    return today.diff(compareDay, 'days') <= 0;
  }
  return false;
}