import { RepeatingJob } from "../../services/RepeatingJob";
import { StayTrackerDevice, GroupMap } from "../../stores/DeviceStore";
import { validatePayment } from "../../services";
import { renderToString } from "react-dom/server";
import React from "react";
import Container from './Container';

interface State {
  key: string,
  label: string,
  timestamp: string,
  value: string,
}
interface StatesResponse {
  orgReference: string,
  deviceReference: string,
  prefix: string,
  states: State[]
}
export class ActiveWindow {
  paymentJob?: RepeatingJob;
  infoWindow?: google.maps.InfoWindow;
  device?: StayTrackerDevice;
  deviceGroups?: GroupMap[];
  organization: string;
  timezone: string;
  updateWindowContent?: NodeJS.Timeout;
  paymentError: boolean = true;
  loadingPayment: boolean = true;

  constructor(organization: string, timezone: string) {
    this.organization = organization;
    this.timezone = timezone;
  }

  clear() {
    if (this.infoWindow && this.paymentJob && this.updateWindowContent) {
      this.infoWindow.close();
      this.infoWindow = undefined;
      clearInterval(this.updateWindowContent);
    }
    if (this.paymentJob) {
      this.paymentJob.cancel();
    }
  }

  setInfoWindow(infoWindow: google.maps.InfoWindow, device: StayTrackerDevice, groups: any) {
    this.infoWindow = infoWindow;
    this.device = device;
    this.loadingPayment = true;

    const checkPaymentState = async (): Promise<void> => {
      try {
        const { states }: StatesResponse = await validatePayment(this.organization, encodeURIComponent(this.device!.device.reference));
        if (states.length > 0) {
          const paymentState = states.find((state: State) => state.key === 'sp_payment_state');
          if (paymentState && paymentState.label === 'paid') {  // ignore old payment format
            if (!this.device!.states) {
              this.device!.states = {};
            }
            this.device!.states.sp_payment_state = {
              currentState: { ...paymentState },
            };
          }
        }
        this.paymentError = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingPayment = false;
        this.setContent();
      }
    }

    this.deviceGroups = [];

    if (groups) {
      for (const group in groups) {
        if (groups.hasOwnProperty(group)) {
          this.deviceGroups.push(groups[group].group);
        }
      }
    }

    this.setContent();
    this.paymentJob = RepeatingJob.doJob(checkPaymentState, 30000);
    this.updateWindowContent = setInterval(() => this.setContent(), 1000);
  }

  public showWindow(map: google.maps.Map, marker: google.maps.Marker): void {
    if (this.infoWindow) {
      this.infoWindow.open(map, marker);
    }
  }

  public setContent(): void {
    if(this.infoWindow) {
      this.infoWindow.setContent(
        renderToString(
          <Container
            device={this.device!}
            groups={this.deviceGroups!}
            timezone={this.timezone}
            isLoading={this.loadingPayment}
            paymentError={this.paymentError}
          />
        )
      );
    }
  }
}