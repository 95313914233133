import React from 'react';

const divStyle = {
  color: 'grey',
  fontSize: '10px',
};

const VERSION = '1.1.0-build.88204aa';

class BuildVersion extends React.Component {

  componentDidMount() {
    this.buildVersion = document.getElementById('buildVersion');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map !== this.props.map) {
      this.props.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(this.buildVersion);
    }
  }

  render() {
    return (
      <div id="buildVersion" style={divStyle}>{VERSION}</div>
    );
  }
}

export default BuildVersion;
