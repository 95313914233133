import { action, computed, decorate, observable } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import { fetchOrgs } from '../services';

class OrganizationStore {
  organizations = observable.array([]);
  loading = true;
  error = {};

  constructor(root) {
    this.root = root;
  }

  get organization() {
    if (!this.root.appStore.organization) {
      return this.organizations[0];
    }

    return this.organizations.find((org) => {
      if (org.organization.id === this.root.appStore.organization) {
        return org;
      }
      return null;
    });
  }

  get organizationSelectOptions() {
    return this.organizations.map((organization) => ({
      key: organization.organization.id,
      value: organization.organization.id,
      text: organization.organization.name,
    }));
  }

  async initData() {
    try {
      this.loading = true;

      const responseData = await fetchOrgs();

      // TODO: Filter orgs server-side
      const organizations = responseData.organizations.filter((org) => {
        const permissions = this.root.userStore.user.permissions;
        const scPermission = permissions.find((it) => it.groupRef === 'smartparking#smartcloud');

        let isGlobal = scPermission && scPermission.roles.some((r) => ['Super Global', 'Global'].includes(r));
        const isRegional = scPermission && scPermission.roles.includes('Regional Global');
        // check if user is regional global for this org or has the app#staytracker groupRef
        if (isRegional && !isGlobal) {
          const orgPermission = permissions.find((it) => it.organization === 'smartparking' && it.groupRef === org.organization.id);
          if (orgPermission && orgPermission.roles.includes('Restricted Global')) {
            const globalOrgApp = permissions.find((it) => it.organization === 'smartparking' && it.groupRef === 'app#staytracker');
            isGlobal = globalOrgApp && globalOrgApp.roles.includes('Global');
          } else if (orgPermission && orgPermission.roles.includes('Global')) {
            isGlobal = true;
          }
        }
        const appPermission = permissions.find((it) => it.groupRef === 'app#staytracker' && it.organization === org.organization.id);
        const hasAppPermission = appPermission && appPermission.roles.includes('User');

        return isGlobal || hasAppPermission;
      });

      this.organizations = observable.array(organizations);
      if (!isEmpty(this.organizations)) {
        this.switchOrg(this.organization.organization.id);
      }

      this.loading = false;
      this.error = {};
    } catch (error) {
      console.error(error);
      this.error = error;
    }
  }

  async switchOrg(organizationId) {
    const switchingOrg = true;
    await this.root.groupStore.getGroups(organizationId, switchingOrg);
    this.root.appStore.changeOrganization(organizationId);
    this.root.deviceStore.getDevices(organizationId);
  }

  clearErrorState() {
    this.error = {};
  }
}

export default decorate(OrganizationStore, {
  organizations: observable,
  loading: observable,
  error: observable,

  organization: computed,
  organizationSelectOptions: computed,

  initData: action,
  switchOrg: action,
  clearErrorState: action,
});
