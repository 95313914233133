import React from 'react';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    marginLeft: 10,
    marginTop: 10,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
  },
  input: {
    width: 200,
    padding: 10,
    backgroundColor: 'transparent',
    border: 'none',
    marginTop: 2,
  },
  autoComplete: {
    backgroundColor: 'white',
    marginTop: 2,
    marginLeft: 0,
    padding: 0,
    width: '100%',
    maxWidth: 218,
    listStyle: 'none',
    zIndex: 1000,
  },
  listItem: {
    fontSize: 14,
    fontWeight: 600,
    padding: '8px 0 8px 15px',
    borderBottom: '1px lightgrey solid',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'lightgrey',
    },
  },
  listItemHead: {
    margin: '2px 0',
    display: 'inline-block',
  },
  deviceId: {
    display: 'inline-block',
    fontSize: 10,
    fontWeight: 400,
    color: 'grey',
    marginLeft: 5,
  },
  occupied: {
    color: 'rgb(199, 24, 62)',
  },
  vacant: {
    color: 'rgb(34, 156, 81)',
  },
  overstay: {
    color: 'rgb(180, 20, 214)',
  },
  '@media (min-width: 1024px)': {
    input: {
      width: 82,
      '&:hover': {
        width: 300,
      },
      '&:focus': {
        width: 300,
      },
      transition: 'width 1s',
    },
    autoComplete: {
      maxWidth: 'unset',
    },
  },
});

class DeviceSearchInput extends React.Component {
  state = {
    devices: [],
  };

  handleSearch = () => {
    this.inputRef.current.value && this.props.onSearch && this.props.onSearch(this.inputRef.current.value);
    this.inputRef.current.value = '';
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (!isEmpty(value)) {
      const { groupStore, deviceStore } = this.props.rootStore;
      const devices = deviceStore
        .validDevicesByGroup(groupStore.site)
        .filter((device) => device.device.name.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 10)
        .sort((a, b) => a.device.name.localeCompare(b.device.name));
      this.setState({ ...this.state, devices: devices || [] });
    } else {
      this.setState({ ...this.state, devices: [] });
    }
  };

  handleDeviceSelect = (device) => () => {
    this.inputRef.current.value = device.device.id;
    this.setState({ ...this.state, devices: [] }, this.handleSearch);
  };

  componentDidMount() {
    this.searchBox = document.getElementById('deviceSearchBox');
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map !== this.props.map) {
      this.props.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(this.searchBox);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div id="deviceSearchBox" className={classNames(classes.root)}>
        <input
          ref={this.inputRef}
          className={classNames(classes.input)}
          placeholder="Search by Bay name"
          onChange={this.handleInputChange}
        />
        <ul className={classNames(classes.autoComplete)}>
          {this.state.devices &&
            this.state.devices.map((device) => (
              <li
                key={device.device.id}
                className={classNames({
                  [classes.listItem]: classes.listItem,
                  [classes.occupied]:
                      device.states && device.states.currentState && device.states.currentState.status === 3,
                  [classes.vacant]:
                      device.states && device.states.currentState && device.states.currentState.status === 0,
                  [classes.overstay]:
                      device.states && device.states.overstayState && device.states.overstayState.overstay,
                })}
                onClick={this.handleDeviceSelect(device)}
              >
                <h4 className={classes.listItemHead}>{device.device.name}</h4>
                <span className={classNames(classes.deviceId)}>{device.sensor && device.sensor.id}</span>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export default withStyles(styles)(inject('rootStore')(observer(DeviceSearchInput)));
