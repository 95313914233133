import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Icon, IconButton, SnackbarContent, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class ErrorToast extends Component {
  handleClose = () => {
    const { userStore } = this.props.rootStore;
    userStore.clearErrorState();
  };

  isOpenSnackbar() {
    const { userStore } = this.props.rootStore;
    return !isEmpty(userStore.error);
  }

  render() {
    const { classes } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.isOpenSnackbar()}
        onClose={this.handleClose}
        autoHideDuration={6000}
      >
        <SnackbarContent
          className={classNames(classes.error)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)}>error</Icon>
              {this.props.rootStore.userStore.error.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <Close className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(inject('rootStore')(observer(ErrorToast)));
