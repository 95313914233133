import React from 'react';
import Row from './Row';
import moment from 'moment-timezone';
import { States } from '../../stores/DeviceStore';

interface LastStateProps {
  states: States,
  timezone: string,
}

export default function LastState({ states, timezone }: LastStateProps): JSX.Element {
  const { sp_occupancyState, sp_overstayPaymentBased, sp_overstayTimeBased,
          sp_overstayNoPaymentReceived } = states;

  const { currentState: cOccupancy, previousState: pOccupancy } = { ...sp_occupancyState };
  const { currentState: pOverstay } = { ...sp_overstayPaymentBased };
  const { currentState: tOverstay } = { ...sp_overstayTimeBased };
  const { currentState: npOverstay } = { ...sp_overstayNoPaymentReceived };

  const getLastStateLabel = (label: string): string => {
    switch(label) {
      case 'Occupied': return 'Arrival';
      case 'Vacant': return 'Departure';
      case 'Time Based': return 'Time-Expired Overstay';
      case 'Payment Based': return 'Payment-Expired Overstay';
      case 'No Payment': return 'No Payment Received Overstay';
      default: return 'state';
    }
  }

  const formatDate = (timestamp: number | string): string => {
    return moment(timestamp).tz(timezone).format('DD/MM/YYYY, HH:mm:ss');
  }

  return (
    <>
      {cOccupancy && cOccupancy.timestamp &&
        <Row label={`Last ${getLastStateLabel(cOccupancy.label)}`} value={formatDate(cOccupancy.timestamp)}/>}
      {pOccupancy && pOccupancy.timestamp &&
        <Row label={`Last ${getLastStateLabel(pOccupancy.label)}`} value={formatDate(pOccupancy.timestamp)}/>}
      {tOverstay && tOverstay.value !== '{}' &&
        <Row label={`Last ${getLastStateLabel('Time Based')}`} value={formatDate(tOverstay.timestamp)}/>}
      {pOverstay && pOverstay.value !== '{}' &&
        <Row label={`Last ${getLastStateLabel('Payment Based')}`} value={formatDate(pOverstay.timestamp)}/>}
      {npOverstay && npOverstay.value !== '{}' && 
        <Row label={`Last ${getLastStateLabel('No Payment')}`} value={formatDate(npOverstay.timestamp)}/>}
    </>
  );
}