import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import config from './config';

/**
 * This config is for firebase to initialize the environment to access NEPTUNE auth/database, not for the firebase
 * project itself which is hosting this project.
 */

const app = firebase.initializeApp(config.firebase);
export default app;
