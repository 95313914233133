import { smartcloudCore } from './instance';

export async function me() {
  return (await smartcloudCore.get('/me')).data
}

export async function fetchDevices(orgId, region, site, subGroupType, subGroup, deviceType, deviceId) {
  let params = '';
  if (region) {
    params += `region=${region}`;
    if (site) {
      params += `&site=${site}`;
      if (subGroupType) {
        params += `&subGroupType=${subGroupType}`;
        if (subGroup) {
          params += `&subGroup=${subGroup}`;
          if (deviceType) {
            params += `&deviceType=${deviceType}`;
            if (deviceId) {
              params += `&deviceId=${deviceId}`;
            }
          }
        }
      }
    }
  }
  const response =  (region && site && subGroupType) ? await smartcloudCore.get(`/organizations/${orgId}/devices/v2/?${params}`) : await smartcloudCore.get(`/organizations/${orgId}/devices`);
  return response.data;
}

export async function validatePayment(orgId, deviceId) {
  const response = await smartcloudCore.get(`/organizations/${orgId}/devices/${deviceId}/states?prefix=sp_payment_state`);
  return response.data;
}

export async function fetchGroups(orgId) {
  const response = await smartcloudCore.get(`/organizations/${orgId}/groups`);
  return response.data;
}

export async function fetchOrgs() {
  const response = await smartcloudCore.get('/organizations');
  return response.data;
}
