import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const styles = (theme) => ({
  spinnerWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Loading = ({ classes, isLoading, render }) => {
  if (isLoading) {
    return (
      <div className={classNames(classes.spinnerWrap)}>
        <CircularProgress/>
        <p></p>
      </div>
    );
  } else {
    return render();
  }
};

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
};

Loading.defaultProps = {
  isLoading: false,
};

export default withStyles(styles)(Loading);
