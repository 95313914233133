import UserStore from './UserStore';
import DeviceStore from './DeviceStore';
import OrganizationStore from './OrganizationStore';
import GroupStore from './GroupStore';
import AppStore from './AppStore';

export default class RootStore {
  constructor() {
    this.appStore = new AppStore(this);
    this.userStore = new UserStore(this);
    this.deviceStore = new DeviceStore(this);
    this.orgStore = new OrganizationStore(this);
    this.groupStore = new GroupStore(this);
  }
}
