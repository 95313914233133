import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import { coreUrl, apiKey } from '../utils/config';

const smartcloudCore = axios.create({ baseURL: coreUrl });

async function setHeaders(config) {
  //  firebase user
  const accessToken = await firebase.auth().currentUser.getIdToken();
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  config.headers['Api-Key'] = apiKey;

  return config;
}

smartcloudCore.interceptors.request.use(setHeaders, (error) => {
  console.error('Unable to execute http request', error);
  Promise.reject(error);
});

export {
  smartcloudCore,
};
