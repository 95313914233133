import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import LoadingOverlay from 'react-loading-overlay';
import GoogleMap from './GoogleMap';

function OccupancyMap({ rootStore }) {
  const [connected, setConnected] = useState(window.navigator.onLine);
  const orgId = rootStore.orgStore && rootStore.orgStore.organization && rootStore.orgStore.organization.organization.id;
  const timezone = rootStore.groupStore && rootStore.groupStore.timezone;
  const location = rootStore.groupStore && rootStore.groupStore.location;

  const devices = rootStore.deviceStore.devices;
  const coaSector = rootStore.appStore.sector && encodeURIComponent(`${rootStore.appStore.site}#${rootStore.appStore.sector}`);
  const groups = rootStore.groupStore && toJS(rootStore.groupStore.groups);


  useEffect(() => {
    // Listen for network status changes
    const networkStatusChanged = () => setConnected(window.navigator.onLine);

    window.addEventListener('online', networkStatusChanged);
    window.addEventListener('offline', networkStatusChanged);

    return () => {
      window.removeEventListener('online', networkStatusChanged);
      window.removeEventListener('offline', networkStatusChanged);
    }
  }, []);

  const getDeviceGroups = (device) => {
    return rootStore.groupStore.deviceGroups(device)
  }

  return (
    <div id='map-container' style={{ height: '100vh', width: '100vw'}}>
      <LoadingOverlay active={!connected} spinner text='Connection Lost, Loading...'>
        <GoogleMap
          orgId={toJS(orgId)}
          timezone={toJS(timezone)}
          location={toJS(location)}
          devices={toJS(devices)}
          groups={groups}
          sectorRef={coaSector}
          deviceGroups={getDeviceGroups}/>
      </LoadingOverlay>
    </div>
  );
}

export default (inject('rootStore')(observer(OccupancyMap)));
