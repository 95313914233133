import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';

const styles = () => ({
  button: {
    width: 40,
    marginTop: 10,
    height: 40,
    padding: '0.5px',
    backgroundColor: '#ff7b6e',
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    marginLeft: 10,
    fontFamily: 'Roboto, Arial, sans-serif',
    border: 'unset',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: 'white',

    '&:hover': {
      backgroundColor: '#ff5246',
    },

    '&:focus': {
      outline: 'unset',
    },
  },
});

class LogoutButton extends React.Component {
  handleLogout = () => {
    const {
      rootStore: { userStore },
    } = this.props;
    userStore.logout();
  };

  componentDidMount() {
    this.logoutBtn = document.getElementById('logout-btn');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map !== this.props.map) {
      this.props.map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(this.logoutBtn);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <button id="logout-btn" className={classes.button} onClick={this.handleLogout}>
        <ExitToApp/>
      </button>
    );
  }
}

export default withStyles(styles)(inject('rootStore')(observer(LogoutButton)));
