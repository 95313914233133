import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ActiveWindow } from '../../../components/InfoWindowContent/ActiveWindow';
import app from '../../../utils/firebase';
import config from '../../../utils/config';
import { Group, States, StayTrackerDevice } from '../../../stores/DeviceStore';
import BuildVersion from '../components/BuildVersion';
import DeviceSearchInput from '../components/DeviceSearchInput';
import LocationButton from '../components/LocationButton';
import LogoutButton from '../components/LogoutButton';
import SiteName from '../components/SiteName';
import { icons } from '../components/images/markerImages';
import firebase from 'firebase';
import { _sc_getDeviceIcon, _sc_getDeviceState } from '../../../services/sc_deviceStateMachine';

interface GoogleMapProps {
  orgId: string;
  location: {
    lat: number;
    lng: number;
  };
  timezone: string;
  devices: StayTrackerDevice[];
  groups: Group[];
  deviceGroups: (device: StayTrackerDevice) => any;
  sectorRef?: string;
}
export default function GoogleMap({ location, timezone, orgId, devices: stDevice, groups, sectorRef, deviceGroups }: GoogleMapProps) {
  const [activeInfoWindow, setActiveInfoWindow] = useState<ActiveWindow>();
  const [map, setMap] = useState<google.maps.Map<HTMLElement>>();
  const [devices, setDevices] = useState<StayTrackerDevice[]>([]);
  const [vacancies, setVacancies] = useState<number>(0);
  const [ref, setRef] = useState<any>();
  const [groupRef, setGroupRef] = useState<firebase.firestore.Query>();
  const vacancyCount = useRef<number>(0);
  const infoWindow = useRef<ActiveWindow>();
  const deviceArray = useRef<StayTrackerDevice[]>();

  vacancyCount.current = vacancies;
  infoWindow.current = activeInfoWindow;
  deviceArray.current = devices;

  useEffect(() => {
    setActiveInfoWindow(new ActiveWindow(orgId, timezone));

    const mapOptions: google.maps.MapOptions = {
      zoom: 17,
      minZoom: 17,
      center: location,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
      fullscreenControl: false,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      streetViewControl: false,
      zoomControl: false,
    };
    const mapDiv = document.getElementById('google-map');
    if (mapDiv) {
      const googleMap = new google.maps.Map(mapDiv, mapOptions);
      const bounds = new google.maps.LatLngBounds();
      if (stDevice.length > 0) {
        const _devices = stDevice.map((device) => {
          const position = {
            lat: parseFloat(device.location.latitude),
            lng: parseFloat(device.location.longitude),
          }
          const marker = new google.maps.Marker({
            map: googleMap,
            position,
            icon: markerIcon({}),
            visible: true,
          });

          marker.addListener('click', () => {
            handleMarkerClick(marker, new google.maps.InfoWindow({ position, maxWidth: 350 }), device.device.reference);
          });
          bounds.extend(position);
          if (sectorRef) {
            googleMap.fitBounds(bounds);
          }
          return { ...device, marker };
        });
        setDevices(_devices);
      }
      setMap(googleMap);
    }
    const dbRef = app.database(`https://${config.firebase.projectId}-tile.firebaseio.com`)
      .ref(`${orgId}/device_states${sectorRef ? `_group/${sectorRef}`: ''}`);
    
    const groupRef = app.firestore().collection(`${orgId}/data/groups`).orderBy('states');

    setRef(dbRef);
    setGroupRef(groupRef);

    return () => {
      dbRef.off('child_added');
      dbRef.off('child_changed');
    }
  }, [location, orgId, timezone, sectorRef, stDevice]);

  const markerIcon = (viewStates: any) => {
    const icon = _sc_getDeviceIcon(viewStates, icons);
    return {
      url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(icon),
      scaledSize: new google.maps.Size(25, 25),
      labelOrigin: new google.maps.Point(10, 30),
      anchor: new google.maps.Point(12, 12),
    }
  }

  const updateState = (snapshot: firebase.database.DataSnapshot) => {
    setImmediate(() => {
      const deviceRef = decodeURIComponent(snapshot.ref.key ?? '');
      const currentStates = snapshot.val().states;
      const targetDeviceIndex = devices.findIndex((it) => it.device.reference === deviceRef);
      if (targetDeviceIndex > -1) {
        devices[targetDeviceIndex].states = currentStates;
        const prevViewStates = devices[targetDeviceIndex].viewStates;
        updateMarker(devices[targetDeviceIndex]);
        // remove set vacancies to avoid keyboard stuck issue
        /*
        const viewStateKeys = Object.keys(devices[targetDeviceIndex].viewStates ?? []);
        const prevViewStateKeys = Object.keys(prevViewStates ?? []);
        if (viewStateKeys.includes('Vacant') && !viewStateKeys.includes('Offline')) {  
          setVacancies(vacancyCount.current + 1);
        } else if (viewStateKeys.includes('Occupied') && !viewStateKeys.includes('Offline') && prevViewStateKeys.includes('Vacant')) {
          setVacancies(vacancyCount.current - 1);
        }
        */
      }
    });
  };

  useEffect(() => {
    ref?.on('child_added', updateState);
    ref?.on('child_changed', updateState);
  }, [ref]);

  useEffect(() => {
    groupRef?.onSnapshot((docSnapshot) => {
      docSnapshot.docChanges().forEach((change)=> {
        setImmediate(() => {
          const group = change.doc;
          const groupRef = decodeURIComponent(group.id);
          const { states } = group.data();
          if (change.type === 'added' || change.type === 'modified') {
            updateGroupState(groupRef, states);
          }
          if (change.type === 'removed') {
            removeGroupStatus(groupRef);
          }
        })

      })
    });
  }, [groupRef]);

  const updateMarker = (device: StayTrackerDevice) => {
    device.viewStates = _sc_getDeviceState(device);
    if (device.marker) {
      device.marker!.setIcon(markerIcon(device.viewStates));
    }
  }

  const updateGroupState = (reference: string, states: any) => {
    try {
      const groupStates: States = {};
      for (const stateKey in states) {
        const obj = JSON.parse(states[stateKey]);
        groupStates[stateKey] = {
          currentState: {
            label: obj.label,
            timestamp: new Date(obj.timestamp).getTime(),
            value: obj.dataStr,
          }
        }
      }
      const groupIndex = groups.findIndex((group) => `${group.group.parent}#${group.group.id}` === reference);
      if (groupIndex > -1) {
        groups[groupIndex] = { ...groups[groupIndex], states: groupStates };
  
        // get the devices related to this group and update their states
        const targetDevices = Object.values(devices).filter((device) => device.groups && Object.keys(device.groups).includes(reference));
  

        updateGroupDeviceStatus(targetDevices, groupStates, groups[groupIndex]);
      }

    } catch (e) {
      console.error(`${reference} states cannot be parsed`);
    }
  }

  const removeGroupStatus = (reference: string) => {
    const targetDevices = devices.filter((device) => device.groups && Object.keys(device.groups).includes(reference));
    targetDevices.forEach((device) => {
      if (device.groupStates) {
        device.groupStates = device.groupStates.filter((it) => it.reference !== reference);
        updateMarker(device);
      }
    })
  }

  const updateGroupDeviceStatus = (groupDevices: StayTrackerDevice[], groupStates: States, group: Group) => {
    /**
     * first -> last order of group type state checking. Types not defined here will ALWAYS appear first
     * i.e timeLimit type group states will always override states of the same key in site type groups
     */
    const precedence = ['lot', 'timeLimit', 'sector', 'site', 'region', 'organization'];
    groupDevices.forEach((device) => {
      const deviceRef = device.device.reference;
      const targetIndex = devices.findIndex((it) => it.device.reference === deviceRef);
      if (targetIndex > -1) {
        if (!devices[targetIndex].groupStates) devices[targetIndex].groupStates = [];
        const existingGroupStateIndex = devices[targetIndex].groupStates!.findIndex((it) => it.reference === `${group.group.parent}#${group.group.id}`) ?? -1;

        if (existingGroupStateIndex > -1) {
          devices[targetIndex].groupStates![existingGroupStateIndex] = { type: group.group.type, states: groupStates, reference: `${group.group.parent}#${group.group.id}` }
        } else {
          devices[targetIndex].groupStates = [...devices[targetIndex].groupStates, { type: group.group.type, states: groupStates, reference: `${group.group.parent}#${group.group.id}` }];
        }
        devices[targetIndex].groupStates!.sort((a, b) => precedence.indexOf(a.type) - precedence.indexOf(b.type));
        updateMarker(devices[targetIndex]);
      }

    });
  }

  const handleMarkerClick = (marker: google.maps.Marker, _infoWindow: google.maps.InfoWindow, reference: string) => {
    const targetDevice = deviceArray.current?.find((it) => it.device.reference === reference);
    if (targetDevice) {
        // clear previous infowindow data (if exists)
        infoWindow.current?.clear();

        // delete firebase based payment state
        delete targetDevice.states?.sp_payment_state;
    
        infoWindow.current?.setInfoWindow(_infoWindow, targetDevice, deviceGroups(targetDevice));
        infoWindow.current?.showWindow(map!, marker);
    
        window.google.maps.event.addListener(_infoWindow, 'domready', infoWindowContainer);
        window.google.maps.event.addListener(_infoWindow, 'closeclick', () => infoWindow.current?.clear());
    }
  }


  const handleSearch = useCallback((deviceId) => {
    const targetDevice = devices.find((it) => it.device.id === deviceId);
    if (targetDevice && map) {
        const location = {
          lat: parseFloat(targetDevice.location.latitude),
          lng: parseFloat(targetDevice.location.longitude),
        };
  
        map.setCenter(location);
        map.setZoom(20);
        window.google.maps.event.trigger(targetDevice.marker, 'click');
    }
  }, [stDevice, map, devices]);

  const handleRelocate = useCallback((position: google.maps.LatLng) => {
    if (map) {
      map.setCenter(position);
    }
  }, [map]);

  const infoWindowContainer = () => {
    const iwOuter = (window as any).$('.gm-style-iw');
    iwOuter.css({ 'z-index': '1', padding: '0px' });
    iwOuter.children(':first-child').css({ width: '100%', overflow: 'auto' });
    const iwBackground = iwOuter.prev();
    if (iwOuter.next().next()) {
      // for mobile, google map will create another image to cover the close button
      iwOuter
        .next()
        .next()
        .css({ right: '30px', top: '10px' });
    }
    iwBackground.children(':nth-child(2)').css({ display: 'none' });
    iwBackground.children(':nth-child(4)').css({ display: 'none' });
    iwBackground.children(':nth-child(1)').attr('style', (i: any, s: any) => s + 'left: 76px !important;');
    iwBackground.children(':nth-child(3)').attr('style', (i: any, s: any) => s + 'left: 76px !important;');
    iwBackground
      .children(':nth-child(3)')
      .find('div')
      .children()
      .css({
        'box-shadow': 'rgba(72, 181, 233, 0.6) 0px 1px 6px',
        'z-index': '1',
      });
    iwBackground
      .children(':nth-child(3)')
      .children()
      .css('top', 0);
    iwBackground
      .children(':nth-child(3)')
      .find('div')
      .children(':first-child')
      .css('background-color', 'rgb(179, 179, 179)');
    const iwCloseBtn = iwOuter.next();
    iwCloseBtn.css({ right: '38px', top: '28px' });
    if ((window as any).$('.iw-content').height() < 140) {
      (window as any).$('.iw-bottom-gradient').css({ display: 'none' });
    }
    iwCloseBtn.mouseout(() => {
      (window as any).$().css({ opacity: '1' });
    });
  };

  return (
    <div id="google-map" style={{ height: document.getElementById('map-container')?.clientHeight ?? '100%', width: '100%' }}>
      <BuildVersion map={map}/>
      <DeviceSearchInput map={map} onSearch={handleSearch} />
      <LogoutButton map={map} />
      <SiteName map={map} vacancies={vacancies} total={devices.length}/>
      <LocationButton map={map} recenter={handleRelocate} />
  </div>
  )
}