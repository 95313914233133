import { action, computed, decorate, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { fetchDevices } from '../services';

/**
 * The group references as they are represented in BigTable.
 *
 * The key is the group reference. The value is either a number and equals 1 or a json string containing the group
 * type, e.g. "{\"type\":\"site\"}"
 */
export interface GroupMap {
  [group: string]: number | string;
}

export enum DeviceType {
  Sys2 = 'sys2',
  Sys2SmartSpot = 'sys2-smartspot',
}

export interface DeviceInfo {
  id: string;
  key: string;
  name: string;
  description: string;
  organization: string;
  reference: string;
  type: DeviceType | string;
}

export interface DeviceSensor {
  id?: string;
}

export interface SCDevice {
  device: DeviceInfo;
  groups: GroupMap;
  location: {
    latitude: string,
    longitude: string,
  };
  metadata: object;
  sensor: DeviceSensor;
}

export interface State {
  label: string,
  timestamp: number | string,
  value: any,
}

export interface DeviceState {
  currentState: State,
  previousState?: State,
}

export type States = { [key: string]: DeviceState };
export interface GroupState {
  reference: string;
  states: States;
  type: string;
}

export interface StayTrackerDevice extends SCDevice {
  maxCountInOneHour?: number;
  offline?: boolean;
  marker?: google.maps.Marker;
  states?: States;
  viewStates?: any;
  groupStates?: GroupState[]; 
}

interface UpdatedDevices {
  devices: Map<string, SCDevice>;
}

export interface Group {
  group: {
    id: string;
    key: string;
    name: string;
    description: string;
    organization: string;
    parent: string;
    type: string;
  },
  metadata: any;
  states: States;
}

class DeviceStore {
  allDevices: StayTrackerDevice[] = [];
  deviceStatusDisposer: any;
  groupStatusDisposer: any;
  updatedDevice: UpdatedDevices = {
    devices: new Map(),
  };
  root: any;

  loading = true;
  error = {};

  constructor(root: any) {
    this.root = root;
  }

  get validDevicesByGroup() {
    return createTransformer((group: any) =>
      this.allDevices
        .filter(this._filterByGroup(group))
        .filter(device => device && device.device && device.device.type === 'sys2')
    );
  }

  get devices() {
    return this.allDevices;
  }

  _filterByGroup(group: any) {
    return (device: SCDevice) => {
      if (!(device && device.groups)) return -1;
      return (
        Object.keys(device.groups).findIndex(groupRef => {
          return groupRef === `${group.group.parent}#${group.group.id}` && device.groups[groupRef];
        }) !== -1
      );
    };
  }

  clearErrorState() {
    this.error = {};
  }

  async fetchDevs(orgId: string, appStore: any): Promise<SCDevice[]> {
    const responseData = await fetchDevices(orgId, appStore.region, appStore.site, appStore.sector ? 'sector' : undefined, appStore.sector);

    return (responseData.devices as SCDevice[])
      .filter(device => ((device.device.type === 'sys2' && device.groups[`${appStore.region}#${appStore.site}`])
        || device.device.type === 'counter'));
  }

  async getDevices(orgId: string) {
    try {
      this.loading = true;
      this.allDevices = [];
      this.updatedDevice.devices.clear();
      const { appStore } = this.root;
      this.allDevices = await this.fetchDevs(orgId, appStore);

      this.loading = false;
      this.error = {};
    } catch (error) {
      console.error('Unable to fetch devices', error);
      this.error = error;
    }
  }

}
export default decorate(DeviceStore, {
  allDevices: observable,
  loading: observable,
  error: observable,
  updatedDevice: observable,
  validDevicesByGroup: computed,

  getDevices: action,
  clearErrorState: action
});