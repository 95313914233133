import React from 'react';
import Row from './Row';
import { readableTime } from './utils';

interface Metadata {
  maximum: number,
  vacanyCount: number,
}

interface CounterProps {
  state: any,
  metadata: object,
}

export default function Counter({ state, metadata }: CounterProps): JSX.Element {
  let occupancy: number | undefined;
  //last update was less than 1 hour ago
  let oneHour: number = new Date().getTime() - (60 * 60 * 1000);
  let recentUpdate: boolean = state && state.lastUpdate && new Date(state.lastUpdate).getTime() > oneHour;

  const getOccupancyLevel = (): string | undefined => {
    if (metadata && (metadata as Metadata).maximum && recentUpdate) {
      occupancy = Number(((((metadata as Metadata).maximum) - (state.vacancyCount as number) * 100) / (metadata as Metadata).maximum).toFixed(1));
      //occupancy levels: 0-50% = green       51-85% = orange     86-100% = red
      if (occupancy <= 50) {
          return 'high';
      }
      else if (occupancy <= 85) {
          return 'moderate';
      }
      else if (occupancy <= 100 || occupancy > 100) {
          return 'low';
      }
    }
    else {
        return 'unknown';
    }
  }

  const getVacancies = (): JSX.Element => {
    return (
      <span className={getOccupancyLevel()}>
        {recentUpdate ? state.vacancyCount : 'Unknown'}
      </span>
    );
  }

  return (
    <>
      <Row label='Vacancies: ' value={getVacancies()}/>
      <Row label='Occupancy: ' value={occupancy && recentUpdate ? `${occupancy}%` : 'Unknown'}/>
      <Row label='Last Updated: ' value={state.lastUpdate && recentUpdate
        ? `${readableTime(state.lastUpdate, true)} ago` : 'Unknown'}/>
    </>
  );
}