import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Select, InputLabel, Input, MenuItem } from '@material-ui/core';

const styles = (theme) => ({
  select: {
    marginBottom: 20,
    minWidth: 240,
  },
  item: {
    paddingTop: 12,
    paddingBottom: 12,
  },
});

const Selector = ({ classes, inputId, label, value, options, onChange, disabled, permitted }) => (
  <FormControl className={classes.select}>
    <InputLabel htmlFor={inputId}>{label}</InputLabel>
    <Select
      value={value}
      input={<Input id={inputId}/>}
      onChange={onChange}
      disabled={disabled || !permitted}
    >
      {options.map((option) => <MenuItem className={classes.item} key={option.key} value={option.value}>{option.text}</MenuItem>)}
    </Select>
  </FormControl>
);

Selector.propTypes = {
  inputId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  disabled: PropTypes.bool.isRequired,
  permitted: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Selector.defaultProps = {
  disabled: false,
  permitted: true,
};

export default withStyles(styles)(Selector);
