import React from 'react';
import Row from './Row';
import { formatDate, readableTime, shouldDisplayPayment } from './utils';
import { DeviceState } from '../../stores/DeviceStore';

interface ContentProps {
 payment: any,
 timezone: string,
}

interface PaymentInfoProps {
  state?: DeviceState,
  timezone: string,
  paymentError: boolean,
}

function Content({ payment, timezone }: ContentProps): JSX.Element {
  const drawPaid = (): JSX.Element => {
    return (
      <span className='vacant'>Paid</span>
    );
  }
  const drawExpired = (): JSX.Element => {
    return (
      <span className='overstay'>{`Expired for ${readableTime(payment.expiryTimestamp)}`}</span>
    );
  }

  return (
    <>
      {shouldDisplayPayment(payment, timezone) ?
        <tbody>
          <Row label='Payment Status' value={payment.expiryTimestamp > new Date() ? drawPaid() : drawExpired()}/>
          <Row label='Payment Value' value={payment.totalPaidAmountFormatted ? payment.totalPaidAmountFormatted : ''}/>
          <Row label='Plate Number' value={payment.rego}/>
          <Row label='Receipt Number' value={payment.ticketNumber ? payment.ticketNumber : ''}/>
          <Row label='Payment Source' value={payment.paymentSource ? payment.paymentSource : ''}/>
          <Row label='Start' value={formatDate(payment.timestamp, timezone)}/>
          <Row label='Expire' value={formatDate(payment.expiryTimestamp, timezone)}/>
        </tbody>
      : <div style={{ height: '4px' }}/>} {/* blank space that retains occupancy-map-content height after loading bar is removed */}
    </>
  );
}


export default function PaymentInfo({ state, timezone, paymentError }: PaymentInfoProps): JSX.Element {
  const drawError = (): JSX.Element => {
    return (
      <tr>
        <td>
          <div id='payment-validation-error'>
            Unable to display payment information.
          </div>
        </td>
      </tr>
    );
  }

  let payment: any;
  try {
    if (!paymentError) {
      payment = state?.currentState && JSON.parse(state!.currentState.value);
    }
  } catch (e) {
    paymentError = true;
  } finally {
    return (
      <table className='occupancy-map-payment-info'>
        {!paymentError ?
          <Content payment={payment} timezone={timezone}/>
        : drawError()}
      </table>
    );
  }
}