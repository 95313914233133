// this is the view component for displaying device states in tiles
// The ultimate goal is that this file does not contain any 'logic' regarding to anything related to states, ie, whatever show up in the states should display onto the tile
// All logic should ideally stays in the backend

export function _sc_getDeviceState(device) {
  const { states, sensor, groupStates = [] } = device;
  const results = {};
  const noMovementDays = 7;     // default no movement is 1 week
  const now = Date.now();
  const get = (statePath, statesObj) => statePath.reduce((acc, val) => (acc && acc[val]) ? acc[val] : undefined, statesObj);

  // gets most relevant group state, if multiple groups with the same type have the same state key, then the most recent state is used
  const getGroupState = (key) => {
    const relevantStates = groupStates.filter((it) => Object.keys(it.states).includes(key));
    if (relevantStates.length > 0) {
        const highestGroupType = relevantStates[0].type;
        const tsSorted = relevantStates.filter((it) => it.type === highestGroupType)
            .sort((a, b) => b.states[key].currentState.timestamp - a.states[key].currentState.timestamp);
        return tsSorted[0].states[key] && tsSorted[0].states[key].currentState;
    }
  }

  //get current key states if they exist
  const occupancyState = get(['sp_occupancyState', 'currentState'], states);
  let paymentState = get(['sp_payment_state', 'currentState'], states);
  const overstayTimeBasedState = get(['sp_overstayTimeBased', 'currentState'], states);
  const overstayPaymentBasedState = get(['sp_overstayPaymentBased', 'currentState'], states);
  const overstayNoPaymentReceivedBasedState = get(['sp_overstayNoPaymentReceived', 'currentState'], states);
  const counterState = get(['sp_uParkState', 'currentState'], states);
  const colourState = get(['sp_colourState', 'currentState'], states);
  const onlineState = get(['sp_onlineState', 'currentState'], states);

  const masterEnforcementState = getGroupState('sp_masterEnforcementState');
  const paymentEnforcementState = getGroupState('sp_paymentEnforcementState');
  const reservationState = getGroupState('sp_reservationState');
  const maxStayState = getGroupState('sp_max_staytime');
  const maintenanceState = getGroupState('sc_maintenanceState');

  if (paymentState && paymentState.label !== 'paid') paymentState = undefined;    //remove old paymentstate structure if still stored

  if (counterState) {        //device is a counter
    results['Counter'] = { vacancyCount: 'Unknown', lastUpdate: 'Unknown' };
    if (counterState.value && device.metadata.maximum) {
      try {
        const counter = JSON.parse(counterState.value);
        results['Counter'] = {
          label: counterState.label,
          vacancyCount: parseInt(counter.vacancyCount),
          maxCapacity: parseInt(device.metadata.maximum),
          lastUpdate: counter.localTimestamp,
        };
      } catch (err) {
        console.error(err);
      }
    }
  }

  if (paymentState) {
    try {
      const payment = JSON.parse(paymentState.value);
      if (occupancyState && onlineState?.value === 'true') {
        // sensor is online, if occupied, payment can be validated with occupied event
        // sensor is online, if vacant, payment will be shown if not expired
         if ( (occupancyState.label === 'Occupied' && payment.timestamp > occupancyState.timestamp) ||
               (occupancyState.label === 'Vacant' && payment.expiryTimestamp > now)) {
           results['Paid'] = { start: payment.timestamp, end: payment.expiryTimestamp };        
         }
      }
      else {
        // sensor is offline, if vacant, payment will be shown if not expired
       if (payment.expiryTimestamp > now) {
         results['Paid'] = { start: payment.timestamp, end: payment.expiryTimestamp };
       }
     }
    } catch (err) {
      console.error(err);
    }
  }

  if (maintenanceState && maintenanceState.value === 'true') {
    results['Maintenance'] = { start: maintenanceState.timestamp, end: 0, label: maintenanceState.label };
  }

  if (reservationState && reservationState.value === 'true') {
    results['Reserved'] = { start: reservationState.timestamp, end: 0, label: reservationState.label };
  }

  if (!sensor || !sensor.id || sensor.id === '') {       // UNASSIGNED
    results['Unassigned'] = { start: 0, end: 0 };
  }
  if (onlineState) {
    if (onlineState.value === 'true') {
        results['Online'] = { start: onlineState.timestamp, end: onlineState.timestamp };
    } else {
        results['Offline'] = { start: onlineState.timestamp, end: onlineState.timestamp };
    }
  } else {
      results['Offline'] = { start: undefined, end: undefined }; // we dont know how long the device has been offline for
  }

  // No movement for some max stay periods * 4
  if (occupancyState && occupancyState.timestamp < now - noMovementDays * 24 * 60 * 60 * 1000) {
    results['Question'] = { start: occupancyState.timestamp, end: 0, label: `No movement (last ${noMovementDays} Days)` };
  }

  //add vacant or occupied state
  if (occupancyState) {
    results[occupancyState.label] = { start: occupancyState.timestamp, end: 0, skipRulesCheck: 0 };
    if (occupancyState.value) {
      try {
        results[occupancyState.label].skipRulesCheck = JSON.parse(occupancyState.value).skipRulesCheck;
      }
      catch(err) {
        console.log(`skipRulesCheck not found`)
      }
    }
  }

  if (masterEnforcementState && masterEnforcementState.value === 'true') {
    //add payment based overstay state
    if (paymentEnforcementState && paymentEnforcementState.value === 'true') {
      if (occupancyState && occupancyState.label === 'Occupied' && overstayPaymentBasedState && paymentState) {
        try {
          const paymentOccupancyFrameId = JSON.parse(occupancyState.value).relatedFrameId;
          const paymentOverstayFrame = JSON.parse(overstayPaymentBasedState.value).relatedFrame;

          if (paymentOverstayFrame && paymentOccupancyFrameId) {
            if (paymentOccupancyFrameId === paymentOverstayFrame.id) {
              results['Overstay'] = { start: overstayPaymentBasedState.timestamp, end: 0 };
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    //add time based overstay state
    if (maxStayState && maxStayState.value !== "") {
      if (occupancyState && occupancyState.label === 'Occupied' && overstayTimeBasedState) {
        try {
          const timeOccupancyFrameId = JSON.parse(occupancyState.value).relatedFrameId;
          const timeOverstayFrame = JSON.parse(overstayTimeBasedState.value).relatedFrame;

          if (timeOverstayFrame && timeOccupancyFrameId && (timeOccupancyFrameId === timeOverstayFrame.id)) {
            results['Overstay'] = { start: overstayTimeBasedState.timestamp, end: 0 };
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    // add no payment received overstay state
    if (occupancyState && occupancyState.value && occupancyState.label === 'Occupied' && overstayNoPaymentReceivedBasedState && overstayNoPaymentReceivedBasedState.value) {
      try {
        const occupancyFrameId = JSON.parse(occupancyState.value).relatedFrameId;
        const overstayFrame = JSON.parse(overstayNoPaymentReceivedBasedState.value).relatedFrame;

        if (overstayFrame && occupancyFrameId && (occupancyFrameId === overstayFrame.id)) {
          results['Overstay'] = { start: overstayNoPaymentReceivedBasedState.timestamp, end: 0 };
          results['NoPaymentReceivedOverstay'] = { start: overstayNoPaymentReceivedBasedState.timestamp, end: 0 };
        }
      }
      catch (err) {
        console.error(err);
      }
    }
  }

  //add colour state
  if (colourState && colourState.value !== '') {
    results['Colour'] = { start: colourState.timestamp, label: colourState.value };
  }

  return results;
}

export function _sc_getSingleDeviceState(states) {
  const stateArray = states ? Object.keys(states) : [];

  if (stateArray.includes('Maintenance')) return 'Maintenance';
  else if (stateArray.includes('Reserved')) return 'Reserved';
  else if (stateArray.includes('Counter')) return 'Counter';
  else if (stateArray.includes('Unassigned') || stateArray.includes('Offline')) return 'Others';
  else if (stateArray.includes('Overstay')) return 'Overstay';
  else if (stateArray.includes('Occupied')) return 'Occupied';
  else if (stateArray.includes('Vacant')) return 'Vacant';
  else if (stateArray.includes('Question')) return 'Question';
  else return 'Others';
}

export function _sc_convertCounterDeviceStates(state) {
  //convert counter value and max vacancy to occupancy/vacancy values
  return (state) ? { vacant: state.vacancyCount, occupied: state.maxCapacity - state.vacancyCount } : null;
}

export function _sc_getDeviceIcon(states, icons) {
  const stateArray = (states) ? Object.keys(states) : [];

  if (stateArray.includes('Maintenance')) return icons.maintenance;
  else if (stateArray.includes('Unassigned') && !stateArray.includes('Counter')) {
    if (stateArray.includes('Paid')) return icons.unassigned_paid;
    else if (stateArray.includes('Reserved')) return icons.unassigned_reserved;
    else return icons.unassigned;
  }
  else if (stateArray.includes('Offline')) {
    if (stateArray.includes('Paid')) return icons.offline_paid;
    else if (stateArray.includes('Reserved')) return icons.offline_reserved;
    else return icons.offline;
  }
  else if (stateArray.includes('Overstay')) {
    if (stateArray.includes('Paid')) return icons.overstay_paid;
    else if (stateArray.includes('Reserved')) return icons.overstay_reserved;
    else if (stateArray.includes('Question')) return icons.overstay_question;
    else return icons.overstay;
  }
  else if (stateArray.includes('Occupied')) {
    if (stateArray.includes('Paid')) return icons.occupied_paid;
    else if (stateArray.includes('Reserved')) return icons.occupied_reserved;
    else if (stateArray.includes('Question')) return icons.occupied_question;
    else return icons.occupied;
  }
  else if (stateArray.includes('Vacant')) {
    if (stateArray.includes('Paid')) return icons.vacant_paid;
    else if (stateArray.includes('Reserved')) return icons.vacant_reserved;
    else if (stateArray.includes('Question')) return icons.vacant_question;
    else return icons.vacant;
  }
  else if (stateArray.includes('Counter')) return icons.uParkCounter;
  else return icons.initialise;
}
