import { decorate, observable, action } from 'mobx';

class AppStore {
  organization;
  region;
  site;
  sector;
  showSelector = false;

  constructor(root) {
    this.root = root;
    this.organization = window.localStorage.getItem('organization') || null;
    this.region = window.localStorage.getItem('region') || null;
    this.site = window.localStorage.getItem('site') || null;
    this.sector = this.getSector();
  }

  changeOrganization(organization) {
    this.organization = organization;
    window.localStorage.setItem('organization', organization);
/*     if (organization === 'cityOfAdelaide' && this.root.appStore.sector === null) {
      this.root.appStore.changeSector(this.root.groupStore.sectorOptions.length > 0 && this.root.groupStore.sectorOptions[0].value || null);
    } 
    else if (organization !== 'cityOfAdelaide') {
      this.root.appStore.sector = null;
    } */
    this.root.appStore.sector = null;
  }

  // 'ALL' sector was removed, so reset the item in local storage if it still exists
  getSector() {
    const tempSector = window.localStorage.getItem('sector');
    return tempSector === 'ALL' ? null : tempSector;
  }

  changeRegion(region) {
    this.region = region;
    window.localStorage.setItem('region', region);
  }
  
  async changeSector(sector, updateSector) {
    this.sector = sector;
    window.localStorage.setItem('sector', sector);
    if (updateSector) {
      await this.root.deviceStore.getDevices(this.organization);
    }
  }

  async changeSite(site, switchingOrg) {
    this.site = site;
    //load new devices only if changing the site
    if (!switchingOrg) await this.root.deviceStore.getDevices(this.organization);
    window.localStorage.setItem('site', site);
  }

  clearLocalStorage() {
    window.localStorage.removeItem('organization');
    window.localStorage.removeItem('region');
    window.localStorage.removeItem('site');
    window.localStorage.removeItem('sector');
  }

  toggleSelector(isShow) {
    this.showSelector = isShow;
  }
}

export default decorate(AppStore, {
  organization: observable,
  region: observable,
  site: observable,
  sector: observable,
  showSelector: observable,

  changeOrganization: action,
  changeRegion: action,
  changeSite: action,
  changeSector: action,
  clearLocalStorage: action,
  toggleSelector: action,
});
