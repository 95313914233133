export class RepeatingJob {
  timeoutId?: NodeJS.Timeout;
  canceled: boolean = false;

  private constructor(job: () => Promise<void>, timeout: number) {
    const work = async (): Promise<void> => {
      try {
        await job();
      } finally {
        if (this.canceled) {
          return;
        }
        this.timeoutId = setTimeout(work, timeout);
      }
    };
    work();
  }

  static doJob(job: () => Promise<void>, timeout: number): RepeatingJob {
    return new RepeatingJob(job, timeout);
  }

  public cancel() {
    this.canceled = true;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }
}